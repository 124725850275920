/* input[type=radio] {
    display: none;
}
 
input[type=radio] + label::before {
    content: '';
    display: inline-block;
    border: 1px solid #000;
    border-radius: 50%;
    margin: 0 0.5em;
}
input[type=radio]:checked + label::before {
    background-color: #ffa;
}

.radio1 + label::before {
    width: 0.5em;
    height: 0.5em;
}

.radio2 + label::before {
    width: 0.75em;
    height: 0.75em;
}

.radio3 + label::before {
    width: 1em;
    height: 1em;
}

.radio4 + label::before {
    width: 1.5em;
    height: 1.5em;
  
    
}

  */

  .freetrial{
      padding: 10px 20px ;
      background: rgb(1, 183, 255);
      border: none;
      outline: none;
      border-radius: 5px ;
      color:rgb(255, 255, 255);
      position: fixed;
      right: 0;
      bottom: 0;
  }